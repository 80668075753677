@use '../abstracts/colors';
@use '../base/typography';
@use '../base/animations';
@use '../base/utilities';
@use '../components/button';

.fourOhFour {
	&HeroContainer {
		display: flex;
		flex-direction: column;
		justify-content: center;
		background-color: colors.$color-grey-light;
	}
	&Hero {
		display: flex;
		position: relative;
		justify-content: center;
		padding: 4rem 4rem 24rem 4rem;
	}
	&Heading {
		font-size: 2.4rem;
		letter-spacing: 0.2rem;
		text-align: center;
		&Side {
			display: flex;
			flex-direction: column;
			gap: 4rem;
			position: absolute;
			z-index: 2;
			justify-content: center;
			align-items: center;
			background-color: colors.$color-white;
			bottom: -22rem;
			right: -2rem;
			left: -2rem;
			padding: 4rem;
			@apply rounded;
		}
		&Lead {
			font-size: 1.8rem;
		}
	}

	&Container {
		position: relative;
	}
	&Image {
		&Container {
			position: relative;
		}
		@apply rounded-xl;
		position: absolute;
	}
}

// ABOVE 544px (For smaller tablets)
@media only screen and (min-width: 34em) {
	.fourOhFour {
		&Hero {
			padding: 4rem 4rem 44rem 4rem;
		}
		&Heading {
			font-size: 2.4rem;
			&Side {
				bottom: -8rem;
				right: -2rem;
				left: -2rem;
			}
			&Lead {
				font-size: 1.8rem;
			}
		}
	}
}

// ABOVE 704px (For Tablets)
@media only screen and (min-width: 44em) {
	.fourOhFour {
		&Hero {
			padding: 4rem 4rem 54rem 4rem;
		}
		&Heading {
			font-size: 2.4rem;
			&Side {
				bottom: -8rem;
				right: -2rem;
				left: -2rem;
			}
			&Lead {
				font-size: 1.8rem;
			}
		}
	}
}
// ABOVE 944px (For Landscape tablets)
@media only screen and (min-width: 59em) {
}

// ABOVE 1200px (For smaller desktops)
@media only screen and (min-width: 75em) {
	.fourOhFour {
		&Heading {
			font-size: 2.4rem;
			&Side {
				bottom: -8rem;
				right: -2rem;
				left: -2rem;
			}
			&Lead {
				font-size: 1.8rem;
			}
		}
	}
}

// ABOVE 1344px (For desktops)
@media only screen and (min-width: 84em) {
}
